import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Stack } from "@mui/material";

import { ListItemInternalModel } from "../../services/internalStorage/models/ListItemInternalModel";
import DividerWithContent from "../DividerWithContent/DividerWithContent";
import { ListItemButton } from "../ListItemButton/ListItemButton";

export interface ListItemsProps {
  listItems: ListItemInternalModel[];
  onListItemComplete: (id: number) => void;
  onListItemUnComplete: (id: number) => void;
  onDeleteAllListItems: () => void;
  onEditListItemClick: (listItemId: number) => void;
}

export const ListItems = (props: ListItemsProps) => {
  const handleListItemClick = (id: number) => {
    props.onListItemComplete(id);
  };
  const handleListItemCompleteClick = (id: number) => {
    props.onListItemUnComplete(id);
  };
  const handleDeleteAllListItems = () => {
    props.onDeleteAllListItems();
  };

  return (
    <Stack spacing="15px" padding="20px">
      {props?.listItems
        ?.filter((listItem) => !listItem.isCompleted)
        .map((listItem) => {
          return (
            <ListItemButton
              key={listItem.localId}
              localId={listItem.localId}
              name={listItem.name!}
              onClick={handleListItemClick}
              isChecked={false}
              color={listItem.color}
              onEditClick={props.onEditListItemClick}
            />
          );
        })}
      {props?.listItems?.findIndex((listItem) => listItem.isCompleted === true) >= 0 ? (
        <Stack>
          <DividerWithContent>
            <Stack flexDirection="row" alignContent="center" alignItems="center">
              <Stack fontSize="18px" marginRight="10px">
                Купленное
              </Stack>
              <Stack flexDirection="row">
                <Stack color={"#d32f2f"} lineHeight="51px">
                  {"("}
                </Stack>
                <Stack>
                  <IconButton
                    onClick={() => handleDeleteAllListItems()}
                    aria-label="delete"
                  >
                    <DeleteIcon fontSize="large" color="error" />
                  </IconButton>
                </Stack>
                <Stack color={"#d32f2f"} lineHeight="51px">
                  {")"}
                </Stack>
              </Stack>
            </Stack>
          </DividerWithContent>
          {props?.listItems
            ?.filter((listItem) => listItem.isCompleted)
            .map((listItem) => {
              return (
                <ListItemButton
                  key={listItem.localId}
                  localId={listItem.localId}
                  name={listItem.name!}
                  onClick={handleListItemCompleteClick}
                  isChecked={true}
                  color={listItem.color}
                  onEditClick={props.onEditListItemClick}
                />
              );
            })}
        </Stack>
      ) : (
        <></>
      )}
    </Stack>
  );
};
