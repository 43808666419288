import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";

import { ListDetailedDto } from "../../../services/openapi";

export interface AcceptListDialogProps {
  open: boolean;
  onAccept: () => void;
  onReject: () => void;
  list: ListDetailedDto | null;
}

export const AcceptListDialog = (props: AcceptListDialogProps) => {
  return (
    <Dialog open={props.open} onClose={props.onReject}>
      <DialogTitle>
        Добавить новый список {props.list?.name}, которым с вами поделился{" "}
        {props.list?.owner?.name}?
      </DialogTitle>
      <DialogActions>
        <Button onClick={props.onAccept}>Да</Button>
        <Button onClick={props.onReject}>Нет</Button>
      </DialogActions>
    </Dialog>
  );
};
