import { createSlice } from "@reduxjs/toolkit";

export type ApplicationSliceType = {
  isOnline: boolean;
};
const initialState: ApplicationSliceType = {
  isOnline: true,
};

export const applicationSlice = createSlice({
  name: "applicationSlice",
  initialState,
  reducers: {
    toggleIsOnline(state, action: { payload: boolean }) {
      state.isOnline = action.payload;
    },
  },
});

export const { toggleIsOnline } = applicationSlice.actions;
