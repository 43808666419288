import { Mic, EditNote } from "@mui/icons-material";
import { IconButton, Stack } from "@mui/material";

import { VoiceAnimation } from "../VoiceAnimation/VoiceAnimation";

export interface ActionsBarProps {
  listening: boolean;
  onTextAddListItem: () => void;
  onVoiceAddListItem: () => void;
}

export const ActionsBar = (props: ActionsBarProps) => {
  return (
    <Stack
      flexDirection="row"
      height="80px"
      width="100%"
      sx={{
        borderBottomColor: "whitesmoke",
        borderBottomStyle: "solid",
        borderBottomWidth: "1px",
      }}
    >
      <Stack width="49%" padding="10px">
        {!props.listening && (
          <IconButton onClick={() => props.onVoiceAddListItem()}>
            <Mic fontSize="large" color="error" />
          </IconButton>
        )}
        {props.listening && <VoiceAnimation />}
      </Stack>
      <Stack width="49%" padding="10px">
        <IconButton onClick={() => props.onTextAddListItem()}>
          <EditNote fontSize="large" color="info" />
        </IconButton>
      </Stack>
    </Stack>
  );
};
