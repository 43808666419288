import { AddCircle, RemoveCircle } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import {
  Chip,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Toolbar,
  Box,
} from "@mui/material";
import { useState } from "react";

import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { createList, removeList, selectList } from "../../store/lists/listsSlice";
import { selectListsState } from "../../store/lists/selectors";
import { CreateListDialog } from "../Dialogs/CreateListDialog/CreateListDialog";
import { DeleteListDialog } from "../Dialogs/DeleteListDialog/DeleteListDialog";

import "./AppTopBar.scss";

export interface AppTopBarProps {
  onMenuClick: () => void;
}

export const AppTopBar = (props: AppTopBarProps) => {
  const dispatch = useAppDispatch();
  const { lists, selectedList } = useAppSelector(selectListsState);
  const [openAddListDialog, setOpenAddListDialog] = useState(false);
  const [openDeleteListDialog, setOpenDeleteListDialog] = useState(false);

  const isOnline: boolean = useAppSelector((state) => state.applicationSlice.isOnline);

  const handleChange = (event: SelectChangeEvent) => {
    const listId = +event.target.value;
    dispatch(selectList({ localId: listId }));
  };

  return (
    <Toolbar className="top-container" variant="dense">
      <div className="top-content">
        <IconButton
          onClick={() => props.onMenuClick()}
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
        <Stack sx={{ width: "100%" }} alignItems="center">
          {selectedList?.name && selectedList.name.length > 0 && (
            <Select
              value={selectedList.localId?.toString()}
              onChange={handleChange}
              className="select-list"
            >
              {lists?.map((list) => (
                <MenuItem
                  className="select-list-item"
                  key={list.localId}
                  value={list.localId}
                >
                  {list.name}
                  <Box className="select-list-owner">{list.owner.name}</Box>
                </MenuItem>
              ))}
            </Select>
          )}
        </Stack>
        {selectedList?.localId && (
          <IconButton
            onClick={() => setOpenDeleteListDialog(true)}
            edge="end"
            color="error"
            aria-label="menu"
          >
            <RemoveCircle />
          </IconButton>
        )}
        <IconButton
          onClick={() => setOpenAddListDialog(true)}
          edge="end"
          color="inherit"
          aria-label="menu"
        >
          <AddCircle />
        </IconButton>
        <CreateListDialog
          open={openAddListDialog}
          onClose={function (): void {
            setOpenAddListDialog(false);
          }}
          onAdd={(name: string) => {
            setOpenAddListDialog(false);
            dispatch(createList(name));
          }}
        />
        {selectedList != null && (
          <DeleteListDialog
            open={openDeleteListDialog}
            listName={selectedList.name!}
            onClose={function (): void {
              setOpenDeleteListDialog(false);
            }}
            onOk={function (): void {
              setOpenDeleteListDialog(false);
              dispatch(removeList(selectedList!.localId!));
            }}
          />
        )}
      </div>
      {!isOnline && (
        <Chip
          icon={<WifiOffIcon style={{ color: "white" }} />}
          label="Вы не в сети"
          style={{ marginLeft: "-10px", background: "#f2994a", color: "white" }}
          size="small"
        />
      )}
    </Toolbar>
  );
};
