import { PaletteMode } from "@mui/material";
import { ThemeOptions } from "@mui/material/styles";

type AppColors = {
  BG: string;
  PS700: string;
  Neutral100: string;
  Neutral200: string;
  Neutral900: string;
};

type ColorThemes = { light: AppColors; dark: AppColors };

const colorThemes: ColorThemes = {
  light: {
    BG: "#FFF",
    PS700: "#08875D",
    Neutral100: "#F8FAFC",
    Neutral200: "#EBF1F5",
    Neutral900: "#1B2227",
  },
  dark: {
    BG: "#FFF",
    PS700: "#08875D",
    Neutral100: "#F8FAFC",
    Neutral200: "#EBF1F5",
    Neutral900: "#1B2227",
  },
};

export const getDesignTokens = (mode: PaletteMode): ThemeOptions => {
  const colors: AppColors = colorThemes[mode];
  return {
    typography: {
      fontFamily: [
        "Inter",
        "-apple-system",
        "BlinkMacSystemFont",
        "Roboto",
        '"Segoe UI"',
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    palette: {
      mode,
      background: {
        default: colors.BG,
        paper: colors.BG,
      },
      text: {
        primary: colors.Neutral900,
      },
      primary: {
        main: colors.PS700,
        contrastText: colors.Neutral100,
      },
      secondary: {
        main: colors.Neutral900,
        contrastText: colors.Neutral100,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
            textTransform: "none",
            width: "max-content",
            padding: "8px 16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "& .MuiButton-startIcon": {
              marginRight: "12px",
              marginLeft: 0,
              "& .MuiSvgIcon-root": {
                fontSize: "24px",
              },
            },
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: colors.Neutral200,
            height: "1px",
            margin: "12px 0px 12px 16px",
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            padding: 0,
            margin: 0,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            padding: 0,
            margin: 0,
          },
        },
      },
    },
  };
};
