import "./VoiceAnimation.scss";

export const VoiceAnimation = () => {
  return (
    <div id="bars">
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
    </div>
  );
};
