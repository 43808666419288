import { TaskAlt } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Button, Stack, Box } from "@mui/material";
import "./ListItemButton.scss";
import React from "react";

import { ListItemInternalColors } from "../../services/internalStorage/models/ListItemInternalModel";

export interface ListItemButtonProps {
  isChecked: boolean;
  onClick: (listItemId: number) => void;
  name: string;
  localId: number;
  color: string;
  onEditClick: (listItemId: number) => void;
}

export const ListItemButton = (props: ListItemButtonProps) => {
  const handleEditClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    props.onEditClick(props.localId);
  };

  return (
    <Stack flexDirection="row" justifyContent="center">
      <Button role="button" onClick={() => props.onClick(props.localId)}>
        <Stack
          minWidth="350px"
          className="button-55"
          flexDirection="row"
          sx={{
            background: `linear-gradient(to right, ${props.color} 4%, ${ListItemInternalColors.DEFAULT} 4%)`,
          }}
        >
          <Stack>
            {props.isChecked ? (
              <TaskAlt style={{ color: "#00dd0c" }} />
            ) : (
              <RadioButtonUncheckedIcon />
            )}
          </Stack>
          <Stack ml={3}>{props.name}</Stack>
          <Box className="list-item-edit" onClick={handleEditClick}>
            <EditIcon className="list-item-edit-icon" />
          </Box>
        </Stack>
      </Button>
    </Stack>
  );
};
