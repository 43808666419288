import { AppBar } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";

import { ActionsBar } from "../../../components/ActionsBar/ActionsBar";
import { AppTopBar } from "../../../components/AppTopBar/AppTopBar";
import { AcceptListDialog } from "../../../components/Dialogs/AcceptListDialog/AcceptListDialog";
import { EditListItemDialog } from "../../../components/Dialogs/EditListItemDialog/EditListItemDialog";
import { ShareListDialog } from "../../../components/Dialogs/ShareListDialog/ShareListDialog";
import { SignInDialog } from "../../../components/Dialogs/SignInDialog/SignInDialog";
import { ListItemCreateTextArea } from "../../../components/ListItemCreateTextArea/ListItemCreateTextArea";
import { ListItems } from "../../../components/ListItems/ListItems";
import { Menu } from "../../../components/Menu/Menu";
import {
  ListItemInternalColors,
  ListItemInternalModel,
} from "../../../services/internalStorage/models/ListItemInternalModel";
import { logoutCleanup } from "../../../store/auth/authSlice";
import { selectAuthState } from "../../../store/auth/selectors";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  createListItem,
  deleteListItems,
  fetchListItems,
  fetchLists,
  getShareListKey,
  toggleListItem,
  updateListItem,
  saveListCategory,
  syncLists,
  getListByShareKey,
  acceptSharedList,
} from "../../../store/lists/listsSlice";
import { selectListsState } from "../../../store/lists/selectors";
import { URL_PARAMS } from "../../../utils/constants";

export const ListsPage = () => {
  const dispatch = useAppDispatch();
  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();
  const [searchParams, setSearchParams] = useSearchParams();
  const { listItems, selectedList, shareListKey, listToAccept } =
    useAppSelector(selectListsState);
  const { user, signedIn } = useAppSelector(selectAuthState);
  const [leftPanelOpened, setLeftPanelOpened] = useState(false);
  const [textListItemsOpened, setTextListItemsOpened] = useState(false);
  const [signInDialogOpened, setSignInDialogOpened] = useState(false);
  const [shareListDialogOpened, setShareListDialogOpened] = useState(false);
  const [editListItemOpened, setEditListItemOpened] = useState(false);
  const [listItemLocalIdToEdit, setListItemLocalIdToEdit] = useState(0);
  const [acceptListDialogOpen, setAcceptListDialogOpen] = useState(false);
  const [shareListKeyParam, setShareListKeyParam] = useState("");

  const toggleDrawer = (newValue: boolean) => {
    setLeftPanelOpened(newValue);
  };

  const addTextListItem = () => {
    setTextListItemsOpened(!textListItemsOpened);
  };

  const addVoiceListItem = () => {
    if (browserSupportsSpeechRecognition) {
      SpeechRecognition.startListening({ language: "ru-RU" }).catch(console.log);
    } else {
      // TODO: display banner your browser doesn't support speech recognition.
    }
  };

  const completeListItem = (localId: number) => {
    if (selectedList) {
      dispatch(toggleListItem({ localId, isCompleted: true }));
    }
  };

  const unCompleteListItem = (localId: number) => {
    if (selectedList) {
      dispatch(toggleListItem({ localId, isCompleted: false }));
    }
  };

  const cancelListItemTextCreation = () => {
    setTextListItemsOpened(false);
  };

  const saveListItemTextCreation = (listItemName: string) => {
    if (selectedList) {
      dispatch(
        createListItem({
          localListId: selectedList.localId,
          name: listItemName,
        }),
      );
      setTextListItemsOpened(false);
    }
  };

  const handleDeleteAllListItems = () => {
    if (selectedList) {
      dispatch(
        deleteListItems({
          listItemsToDelete: listItems.filter((listItem) => listItem.isCompleted),
        }),
      );
    }
  };

  const handleLogout = () => {
    dispatch(logoutCleanup()).then(() => {
      window.location.reload();
    });
  };

  const handleOpenEditListItemDialog = (listItemLocalId: number) => {
    setListItemLocalIdToEdit(listItemLocalId);
    setEditListItemOpened(true);
  };

  const handleCloseEditListItemDialog = () => {
    setEditListItemOpened(false);
  };

  const handleShareListDialogOpen = () => {
    if (selectedList !== null) {
      setShareListDialogOpened(true);
    }
  };

  const fetchShareKey = (listId: number | null) => {
    if (listId === null) {
      dispatch(syncLists());
    } else {
      dispatch(getShareListKey(listId));
    }
  };

  const handleListItemColorChange = (color: ListItemInternalColors, order: number) => {
    setEditListItemOpened(false);
    const listItemToUpdate = listItems.find((li) => li.localId === listItemLocalIdToEdit);
    if (listItemToUpdate) {
      const updatedListItem: ListItemInternalModel = {
        ...listItemToUpdate,
        color,
        order,
      };
      dispatch(
        saveListCategory({
          categoryColor: color,
          listItemName: listItemToUpdate.name || "",
        }),
      );
      dispatch(updateListItem({ listItem: updatedListItem }));
    }
  };

  const handleAcceptList = () => {
    if (shareListKeyParam) {
      dispatch(acceptSharedList(shareListKeyParam));
      setAcceptListDialogOpen(false);
    }
  };

  const handleRejectList = () => {
    setAcceptListDialogOpen(false);
  };

  useEffect(() => {
    if (selectedList?.localId) {
      dispatch(fetchListItems(selectedList.localId));
    }
  }, [dispatch, selectedList]);

  useEffect(() => {
    dispatch(fetchLists());
    const shareListKeyParam = searchParams.get(URL_PARAMS.SHARE_LIST_KEY);
    if (shareListKeyParam) {
      setShareListKeyParam(shareListKeyParam);
      dispatch(getListByShareKey(shareListKeyParam))
        .then(() => {
          setAcceptListDialogOpen(true);
        })
        .finally(() => {
          searchParams.delete(URL_PARAMS.SHARE_LIST_KEY);
          setSearchParams(searchParams);
        });
    }
  }, []);

  useEffect(() => {
    if (selectedList && !listening && transcript !== null && transcript.length > 1) {
      dispatch(
        createListItem({
          localListId: selectedList.localId!,
          name: transcript,
        }),
      );
      resetTranscript();
    }
  }, [listening]);

  return (
    <Stack height="100vh" sx={{ background: "#282c34" }}>
      <AppBar position="static">
        <AppTopBar onMenuClick={() => toggleDrawer(true)} />
        <Menu
          open={leftPanelOpened}
          onClose={() => setLeftPanelOpened(false)}
          user={user}
          onSignInClick={() => setSignInDialogOpened(true)}
          onSignOutClick={handleLogout}
          onShareList={handleShareListDialogOpen}
        />
        <SignInDialog
          open={signInDialogOpened}
          onClose={() => setSignInDialogOpened(false)}
        />
      </AppBar>
      {selectedList?.localId && (
        <Stack flexDirection="column" width="100%">
          <ActionsBar
            listening={listening}
            onTextAddListItem={addTextListItem}
            onVoiceAddListItem={addVoiceListItem}
          />
          <ListItemCreateTextArea
            onCancel={cancelListItemTextCreation}
            onSave={saveListItemTextCreation}
            open={textListItemsOpened}
          />
          <ListItems
            onListItemComplete={completeListItem}
            onListItemUnComplete={unCompleteListItem}
            onDeleteAllListItems={handleDeleteAllListItems}
            listItems={listItems}
            onEditListItemClick={handleOpenEditListItemDialog}
          />
          <EditListItemDialog
            open={editListItemOpened}
            onClose={handleCloseEditListItemDialog}
            onColorPick={handleListItemColorChange}
          />
        </Stack>
      )}
      {selectedList && (
        <ShareListDialog
          open={shareListDialogOpened}
          onClose={() => setShareListDialogOpened(false)}
          signedIn={signedIn}
          onFetchShareKey={fetchShareKey}
          shareListKey={shareListKey}
          selectedList={selectedList}
        />
      )}
      <AcceptListDialog
        open={acceptListDialogOpen}
        onAccept={handleAcceptList}
        onReject={handleRejectList}
        list={listToAccept}
      />
    </Stack>
  );
};
