import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";

import { ListInternalModel } from "../../../services/internalStorage/models/ListInternalModel";
import { ShareList } from "../../ShareList/ShareList";
import { SignIn } from "../../SignIn/SignIn";

export interface ShareListDialogProps {
  open: boolean;
  onClose: () => void;
  signedIn: boolean;
  onFetchShareKey: (listId: number | null) => void;
  shareListKey: string | null;
  selectedList: ListInternalModel;
}

export const ShareListDialog = (props: ShareListDialogProps) => {
  const [signInButtonClicked, setSignInButtonClicked] = useState(false);

  const handleClose = () => {
    setSignInButtonClicked(false);
    props.onClose();
  };

  return (
    <Dialog open={props.open} onClose={handleClose}>
      <DialogTitle>
        {props.signedIn
          ? "Поделиться списком"
          : "Хотите воспользоваться всеми возможностями?"}
      </DialogTitle>
      <DialogContent>
        {props.signedIn ? (
          <ShareList
            onFetchShareKey={props.onFetchShareKey}
            shareListKey={props.shareListKey}
            selectedList={props.selectedList}
          />
        ) : (
          signInButtonClicked && <SignIn />
        )}
      </DialogContent>
      {!props.signedIn && !signInButtonClicked && (
        <DialogActions>
          <Button onClick={() => setSignInButtonClicked(true)}>Войти</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
