import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";

import { SignIn } from "../../SignIn/SignIn";

export interface SignInDialogProps {
  open: boolean;
  onClose: () => void;
}

export const SignInDialog = (props: SignInDialogProps) => {
  return (
    <Dialog open={props.open} onClose={() => props.onClose}>
      <DialogTitle>Войдите в аккаунт</DialogTitle>
      <DialogContent>
        <SignIn onSuccessSignIn={() => props.onClose()} />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()}>Отменить</Button>
      </DialogActions>
    </Dialog>
  );
};
