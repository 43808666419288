import AuthService from "../../auth/AuthService";
import { ListInternalModel } from "../../internalStorage/models/ListInternalModel";
import { ListItemInternalModel } from "../../internalStorage/models/ListItemInternalModel";
import { InternalStorageListsService } from "../../internalStorage/services/InternalStorageListsService";
import { ListDetailedDto, ListsService } from "../../openapi";

import { SequentialTaskRunner } from "./SequentialTaskRunner";

export class SyncListsService {
  private static turnedOn = false;
  private static sequentialTaskRunner = new SequentialTaskRunner();
  private static updateStore: () => void;

  private static async syncLists(
    serverDetailedLists: ListDetailedDto[],
    localLists: ListInternalModel[],
  ): Promise<void> {
    const promises: Promise<unknown>[] = [];

    serverDetailedLists.forEach((serverList) => {
      const localCopy = localLists.find((ll) => ll.id === serverList.id);
      if (localCopy === undefined) {
        // create local copy
        promises.push(
          InternalStorageListsService.createList({
            ...serverList,
            owner: serverList.owner!,
          }),
        );
      } else if (localCopy.deleted !== null) {
        // delete local and server copies
        promises.push(ListsService.deleteApiLists(serverList.id));
        promises.push(InternalStorageListsService.deleteList(localCopy.localId, false));
      } else {
        // TODO: compare local and server lists and update to latest
      }
    });

    localLists.forEach((localList) => {
      const serverCopy = serverDetailedLists.find((sl) => sl.id === localList.id);
      if (serverCopy === undefined) {
        if (localList.id === null) {
          // create server copy
          promises.push(
            (async () => {
              const newServerList = await ListsService.postApiLists({
                name: localList.name,
                created: localList.created,
                updated: localList.updated,
              });
              await InternalStorageListsService.updateList({
                ...localList,
                id: newServerList.id,
                owner: newServerList.owner || localList.owner,
                order: newServerList.order,
              });
            })(),
          );
        } else {
          // delete local copy
          promises.push(InternalStorageListsService.deleteList(localList.localId, false));
        }
      }
    });
    await Promise.all(promises);
  }

  private static async syncListItems(
    serverDetailedLists: ListDetailedDto[],
    localListItems: ListItemInternalModel[],
  ): Promise<void> {
    const serverListItems = serverDetailedLists.flatMap(
      (list) => list.items?.map((item) => ({ ...item, listId: list.id })) ?? [],
    );
    const promises: Promise<unknown>[] = [];

    serverListItems.forEach((serverListItem) => {
      const localCopy = localListItems.find((li) => li.id === serverListItem.id);
      if (localCopy === undefined) {
        // create local copy
        promises.push(
          (async () => {
            const localList = (await InternalStorageListsService.getLists()).find(
              (l) => l.id === serverListItem.listId,
            );
            if (localList && localList.localId) {
              await InternalStorageListsService.createListItem({
                ...serverListItem,
                localListId: localList.localId,
              });
            }
          })(),
        );
      } else if (localCopy.deleted !== null) {
        // delete local and server copies
        promises.push(
          ListsService.deleteApiListsItem(serverListItem.listId, serverListItem.id),
        );
        promises.push(
          InternalStorageListsService.deleteListItem(localCopy.localId, false),
        );
      } else {
        // compare local and server list items and update to latest
        if (serverListItem.updated) {
          const serverCopyUpdated = new Date(serverListItem.updated).valueOf();
          const localCopyUpdated = new Date(localCopy.updated).valueOf();
          if (localCopyUpdated > serverCopyUpdated) {
            promises.push(
              ListsService.putApiListsItem(serverListItem.listId, serverListItem.id, {
                isCompleted: localCopy.isCompleted,
                updated: localCopy.updated,
              }),
            );
          } else if (localCopyUpdated < serverCopyUpdated) {
            promises.push(
              InternalStorageListsService.updateListItem({
                ...localCopy,
                isCompleted: serverListItem.isCompleted,
                updated: serverListItem.updated,
              }),
            );
          }
        }
      }
    });

    localListItems.forEach((localListItem) => {
      const serverCopy = serverListItems.find((li) => li.id === localListItem.id);
      if (serverCopy === undefined) {
        if (localListItem.id === null) {
          // create server copy
          promises.push(
            (async () => {
              const localList = (await InternalStorageListsService.getLists()).find(
                (l) => l.localId === localListItem.localListId,
              );
              if (localList !== undefined && localList.id !== null) {
                const newServerListItem = await ListsService.postApiListsItem(
                  localList.id,
                  { name: localListItem.name },
                );
                return InternalStorageListsService.updateListItem({
                  ...localListItem,
                  id: newServerListItem.id,
                });
              }
            })(),
          );
        } else {
          // delete local copy
          promises.push(
            InternalStorageListsService.deleteListItem(localListItem.localId, false),
          );
        }
      }
    });
    await Promise.all(promises);
  }

  private static async syncListsAndItems(): Promise<void> {
    const serverDetailedLists = await ListsService.getApiListsDetailed();
    const localLists = await InternalStorageListsService.getLists();
    const localListItems = await InternalStorageListsService.getAllListItems();
    await this.syncLists(serverDetailedLists, localLists);
    await this.syncListItems(serverDetailedLists, localListItems);
  }

  public static enqueue(): void {
    this.sequentialTaskRunner.enqueue(async () => {
      const userEmail = await AuthService.getUserEmail();
      if (userEmail) {
        console.time("Lists synced");
        console.log("Synchronization is running...");
        await this.syncListsAndItems();
        if (this.updateStore) {
          this.updateStore();
        }
        console.timeEnd("Lists synced");
      }
    });
  }

  public static run(updateStore: () => void, interval: number): void {
    if (!this.turnedOn) {
      this.updateStore = updateStore;
      setInterval(() => this.enqueue(), interval);
      this.turnedOn = true;
    }
  }
}
