export enum ListItemInternalColors {
  BLUE = "#26b0c7",
  GREEN = "#75b35a",
  PINK = "#ff5699",
  BROWN = "#e57542",
  RED = "#ba2e38",
  PURPLE = "#864f9e",
  YELLOW = "#c1c12f",
  DEFAULT = "#1976d2",
}

export type ListItemInternalModel = {
  id: number | null;
  name: string;
  isCompleted: boolean;
  created: string;
  updated: string;
  listId: number | null;
  localId: number;
  localListId: number;
  color: ListItemInternalColors;
  order: number;
  deleted: string | null;
};
