import "@fontsource/inter";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useEffect, useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import { useSpeechRecognition } from "react-speech-recognition";

import config from "../appConfig/config";
import { toggleIsOnline } from "../store/application/applicationSlice";
import { checkSignIn, getAndSaveUserProfile } from "../store/auth/authSlice";
import { selectAuthState } from "../store/auth/selectors";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { runSyncLists } from "../store/lists/listsSlice";
import { getDesignTokens } from "../theme";

import { ListsPage } from "./pages/ListsPage/ListsPage";

export const App = () => {
  const dispatch = useAppDispatch();
  const { signedIn } = useAppSelector(selectAuthState);
  const { browserSupportsSpeechRecognition } = useSpeechRecognition();

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn&apos;t support speech recognition.</span>;
  }

  useEffect(() => {
    dispatch(runSyncLists(config.SyncListsInterval));
    dispatch(checkSignIn());

    window.addEventListener("offline", () => dispatch(toggleIsOnline(false)));
    window.addEventListener("online", () => dispatch(toggleIsOnline(true)));
    return () => {
      window.removeEventListener("offline", () => dispatch(toggleIsOnline(false)));
      window.removeEventListener("online", () => dispatch(toggleIsOnline(true)));
    };
  }, []);

  useEffect(() => {
    if (signedIn) {
      dispatch(getAndSaveUserProfile());
    }
  }, [signedIn]);

  const theme = useMemo(() => createTheme(getDesignTokens("light")), []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GoogleOAuthProvider clientId={config.GoogleApiClientId}>
        <Routes>
          <Route path="/" element={<ListsPage />} />
        </Routes>
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
};
