import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";

export interface CreateListDialogProps {
  open: boolean;
  onClose: () => void;
  onAdd: (name: string) => void;
}

export const CreateListDialog = (props: CreateListDialogProps) => {
  const [open, setOpen] = useState(props.open);
  const [newListName, setNewListName] = useState("");
  const handleClose = () => {
    props.onClose();
  };
  const handleAdd = () => {
    props.onAdd(newListName);
  };

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const changeEvent = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNewListName(event.target.value);
  };

  return (
    <Dialog open={open} onClose={props.onClose}>
      <DialogTitle>Добавить доску</DialogTitle>
      <DialogContent>
        <TextField
          onChange={changeEvent}
          autoFocus
          margin="dense"
          id="name"
          label="Название"
          type="text"
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Отменить</Button>
        <Button onClick={handleAdd} disabled={!newListName}>
          Добавить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
