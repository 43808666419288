export class SequentialTaskRunner {
  private queue: (() => Promise<void>)[];
  private isRunning: boolean;

  constructor() {
    this.queue = [];
    this.isRunning = false;
  }

  private async runNext(): Promise<void> {
    if (this.isRunning || this.queue.length === 0) {
      return;
    }

    this.isRunning = true;

    while (this.queue.length > 0) {
      const nextAction = this.queue.shift();
      if (nextAction) {
        try {
          await nextAction();
        } catch (e) {
          console.log(e);
        }
      }
    }

    this.isRunning = false;
  }

  public enqueue(action: () => Promise<void>): void {
    this.queue.push(action);

    if (!this.isRunning) {
      this.runNext().catch(console.log);
    }
  }
}
