export enum URL_PARAMS {
  SHARE_LIST_KEY = "shareListKey",
}
export enum LOCAL_STORAGE_KEYS {
  ACCESS_TOKEN = "access_token",
  REFRESH_TOKEN = "refresh_token",
  USER_EMAIL = "user_email",
  USER_NAME = "user_name",
  USER_LOGO_URL = "user_logo_url",
}
