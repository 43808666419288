import React, { ReactNode } from "react";
import "./DividerWithContent.scss";

type Props = {
  children: string | JSX.Element | JSX.Element[] | ReactNode;
};

const DividerWithContent = ({ children }: Props) => (
  <div className="container">
    <div className="border" />
    <span className="content">{children}</span>
    <div className="border" />
  </div>
);

export default DividerWithContent;
