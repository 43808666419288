import { Stores } from "../core/Stores";
import getDb from "../core/getDb";
import { ListItemCategoryInternalModel } from "../models/ListItemCategoryInternalModel";
import { ListItemInternalColors } from "../models/ListItemInternalModel";

export class InternalStorageCategoriesService {
  public static async getCategoryByListItemName(
    name: string,
    dbName?: string,
  ): Promise<ListItemInternalColors> {
    const db = await getDb(dbName);
    try {
      const tx = db.transaction(Stores.LIST_ITEM_CATEGORIES, "readonly");
      return await tx.store.get(name.toLocaleLowerCase());
    } catch (e) {
      return ListItemInternalColors.DEFAULT;
    }
  }
  public static async addListsCategory(
    { categoryColor, listItemName }: ListItemCategoryInternalModel,
    dbName?: string,
  ): Promise<ListItemInternalColors> {
    const db = await getDb(dbName);
    const tx = db.transaction(Stores.LIST_ITEM_CATEGORIES, "readwrite");
    await Promise.all([
      tx.store.put(categoryColor.toLocaleLowerCase(), listItemName),
      tx.done,
    ]);
    return categoryColor;
  }
}
