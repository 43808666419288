import { DefaultCopyField } from "@eisberg-labs/mui-copy-field";
import LinkIcon from "@mui/icons-material/Link";
import SendIcon from "@mui/icons-material/Send";
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Box, Button, IconButton, List, ListItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import "./ShareList.scss";
import { ListInternalModel } from "../../services/internalStorage/models/ListInternalModel";
import { URL_PARAMS } from "../../utils/constants";

export interface ShareListProps {
  onFetchShareKey: (listId: number | null) => void;
  shareListKey: string | null;
  selectedList: ListInternalModel;
}

export enum ShareListButtons {
  CopyLink = "CopyLink",
  WhatsApp = "WhatsApp",
  Telegram = "Telegram",
}

export const ShareList = (props: ShareListProps) => {
  const [tab, setTab] = useState(ShareListButtons.CopyLink);
  const [shareLink, setShareLink] = useState("");

  useEffect(() => {
    props.onFetchShareKey(props.selectedList.id);
  }, [props.selectedList.id]);

  useEffect(() => {
    if (props.shareListKey !== null) {
      setShareLink(
        `${window.location.href}?${URL_PARAMS.SHARE_LIST_KEY}=${props.shareListKey}`,
      );
    }
  }, [props.shareListKey]);

  const handleWhatsAppClick = () => {
    window.open(`https://wa.me/?text=${shareLink}`);
  };

  const handleTelegramClick = () => {
    window.open(`https://t.me/share/url?url=${shareLink}`);
  };

  if (!shareLink) {
    return <>Загрузка...</>;
  }

  return (
    <Box className="share-list">
      <Box className="share-list-content">
        {tab === ShareListButtons.CopyLink && (
          <DefaultCopyField label="Скопировать ссылку" value={shareLink} />
        )}
        {tab === ShareListButtons.WhatsApp && (
          <Button
            className="share-list-whats-app"
            variant="contained"
            endIcon={<SendIcon />}
            onClick={handleWhatsAppClick}
          >
            Отправить в WhatsApp
          </Button>
        )}
        {tab === ShareListButtons.Telegram && (
          <Button
            className="share-list-telegram"
            variant="contained"
            endIcon={<SendIcon />}
            onClick={handleTelegramClick}
          >
            Отправить в Телеграм
          </Button>
        )}
      </Box>
      <Box>
        <List className="share-list-tabs">
          <ListItem className="share-list-tabs-item">
            <IconButton
              size="large"
              onClick={() => setTab(ShareListButtons.CopyLink)}
              className={`${tab === ShareListButtons.CopyLink && "share-list-copy"}`}
            >
              <LinkIcon fontSize="large" />
            </IconButton>
            <Typography className="share-list-tabs-item-text">Ссылка</Typography>
          </ListItem>
          <ListItem className="share-list-tabs-item">
            <IconButton
              size="large"
              onClick={() => setTab(ShareListButtons.WhatsApp)}
              className={`${tab === ShareListButtons.WhatsApp && "share-list-whats-app"}`}
            >
              <WhatsAppIcon fontSize="large" />
            </IconButton>
            <Typography className="share-list-tabs-item-text">WhatsApp</Typography>
          </ListItem>
          <ListItem className="share-list-tabs-item">
            <IconButton
              size="large"
              onClick={() => setTab(ShareListButtons.Telegram)}
              className={`${tab === ShareListButtons.Telegram && "share-list-telegram"}`}
            >
              <TelegramIcon fontSize="large" />
            </IconButton>
            <Typography className="share-list-tabs-item-text">Телеграм</Typography>
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};
