import { configureStore } from "@reduxjs/toolkit";

import { applicationSlice } from "./application/applicationSlice";
import { authSlice } from "./auth/authSlice";
import { listsSlice } from "./lists/listsSlice";

export const store = configureStore({
  reducer: {
    [authSlice.name]: authSlice.reducer,
    [listsSlice.name]: listsSlice.reducer,
    [applicationSlice.name]: applicationSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
