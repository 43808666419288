import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { App } from "./app/App";
import * as serviceWorkerRegistration from "./serviceWorkers/serviceWorkerRegistration";
import { initServices } from "./services/initServices";
import { Providers } from "./store/provider";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

initServices();

root.render(
  <React.StrictMode>
    <Providers>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Providers>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
