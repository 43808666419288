import { Dialog, DialogTitle } from "@mui/material";
import Grid from "@mui/material/Grid";

import { ListItemInternalColors } from "../../../services/internalStorage/models/ListItemInternalModel";

export interface EditListItemDialogProps {
  open: boolean;
  onClose: () => void;
  onColorPick: (color: ListItemInternalColors, order: number) => void;
}

export const EditListItemDialog = (props: EditListItemDialogProps) => {
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle textAlign={"center"}>Выберете категорию</DialogTitle>
      <Grid container>
        {Object.values(ListItemInternalColors).map((color, index) => {
          return (
            <Grid
              sx={{
                border: "5px solid white",
                height: "120px",
                width: "120px",
                background: color,
                cursor: "pointer",
              }}
              key={color}
              item
              xs={3}
              onClick={() => props.onColorPick(color, index)}
            />
          );
        })}
      </Grid>
    </Dialog>
  );
};
