import { AddCircle, Cancel } from "@mui/icons-material";
import { IconButton, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";

export interface ListItemCreateTextAreaProps {
  open: boolean;
  onSave: (listItemName: string) => void;
  onCancel: () => void;
}

export const ListItemCreateTextArea = (props: ListItemCreateTextAreaProps) => {
  const [newListItemText, setNewListItemText] = useState("");
  const checkIfEnter = (code: string) => {
    if (code === "Enter" && newListItemText) {
      props.onSave(newListItemText);
    }
  };

  useEffect(() => {
    setNewListItemText("");
  }, [props.open]);

  return (
    <>
      {props?.open && (
        <Stack
          flexDirection="row"
          spacing={1}
          justifyContent="center"
          alignItems="center"
          sx={{ backgroundColor: "whitesmoke" }}
        >
          <TextField
            autoFocus
            size="small"
            onChange={(e) => setNewListItemText(e.target.value)}
            onKeyDown={(e) => checkIfEnter(e.code)}
            label="Введите название"
            variant="outlined"
            value={newListItemText}
          />
          <IconButton
            disabled={!newListItemText}
            onClick={() => props.onSave(newListItemText)}
          >
            <AddCircle fontSize="large" color="success" />
          </IconButton>
          <IconButton onClick={() => props.onCancel()}>
            <Cancel fontSize="large" color="error" />
          </IconButton>
        </Stack>
      )}
    </>
  );
};
