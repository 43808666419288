import { Button, Snackbar } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import { useEffect } from "react";

import {
  getAndSaveUserProfile,
  initTokens,
  toggleAuthError,
} from "../../store/auth/authSlice";
import { selectAuthState } from "../../store/auth/selectors";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { fetchLists } from "../../store/lists/listsSlice";

export interface SignInProps {
  onSuccessSignIn?: () => void;
}

export const SignIn = (props: SignInProps) => {
  const dispatch = useAppDispatch();
  const { authError } = useAppSelector(selectAuthState);

  useEffect(() => {
    dispatch(toggleAuthError(false));
    return () => {
      dispatch(toggleAuthError(false));
    };
  }, []);

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const successInit = await dispatch(initTokens(codeResponse.code));
      if (successInit.payload) {
        const user = await dispatch(getAndSaveUserProfile());
        if (user.payload) {
          dispatch(fetchLists());
        }
        if (props.onSuccessSignIn !== undefined) {
          props.onSuccessSignIn();
        }
      }
    },
    flow: "auth-code",
  });

  return (
    <>
      <Button onClick={() => login()}>Выбрать Аккаунт Google</Button>
      <Snackbar open={authError} message="Ошибка авторизации" />
    </>
  );
};
