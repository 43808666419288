import axios from "axios";

import config from "../../appConfig/config";
import { LOCAL_STORAGE_KEYS } from "../../utils/constants";
import { User } from "../openapi";
import * as api from "../openapi/services/AuthService";

export default class AuthService {
  static async initTokens(authCode: string) {
    // TODO: move this call to backend as we cannot store client_secret in public
    const response = await axios.post("https://oauth2.googleapis.com/token", {
      client_id: config.GoogleApiClientId,
      client_secret: "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",
      grant_type: "authorization_code",
      code: authCode,
      redirect_uri: window.location.origin,
    });

    window.localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, response.data.id_token);
    window.localStorage.setItem(
      LOCAL_STORAGE_KEYS.REFRESH_TOKEN,
      response.data.refresh_token,
    );
  }

  static async getAccessToken(): Promise<string | null> {
    return window.localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  }

  static async getUserEmail(): Promise<string | null> {
    return window.localStorage.getItem(LOCAL_STORAGE_KEYS.USER_EMAIL);
  }

  static async getAndSaveUserProfile(): Promise<User> {
    const result = await api.AuthService.getApiAuthProfile();
    window.localStorage.setItem(
      LOCAL_STORAGE_KEYS.USER_NAME,
      result.name || "Не залогиненный пользователь",
    );
    window.localStorage.setItem(
      LOCAL_STORAGE_KEYS.USER_LOGO_URL,
      result.profileUrl || "",
    );
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.USER_EMAIL, result.email || "");
    return result;
  }

  static async logoutCleanup() {
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_NAME);
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_LOGO_URL);
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
    window.localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_EMAIL);
  }

  static async refreshTokens(): Promise<boolean> {
    const refreshToken = window.localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);

    if (!refreshToken) {
      return false;
    }

    try {
      // TODO: move this call to backend as we cannot store client_secret in public
      const response = await axios.post("https://www.googleapis.com/oauth2/v4/token", {
        client_id: config.GoogleApiClientId,
        client_secret: "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",
        grant_type: "refresh_token",
        refreshToken: refreshToken,
        token_type: "Bearer",
      });

      if (response.status === 200 && response.data?.id_token) {
        window.localStorage.setItem(
          LOCAL_STORAGE_KEYS.ACCESS_TOKEN,
          response.data.id_token,
        );
        await this.getAndSaveUserProfile();

        return true;
      }
    } catch (err) {
      console.log("Error during refresh token request", err);
    }

    return false;
  }
}
