import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import AuthService from "../../services/auth/AuthService";
import { InternalStorageListsService } from "../../services/internalStorage/services/InternalStorageListsService";
import { User } from "../../services/openapi";
import { SyncListsService } from "../../services/sync/services/SyncListsService";

export type AuthStateType = {
  signedIn: boolean;
  user: User | null;
  authError: boolean;
};

const initialState: AuthStateType = {
  signedIn: false,
  user: null,
  authError: false,
};

export const checkSignIn = createAsyncThunk<boolean, void>(
  "auth/checkSignIn",
  async () => {
    try {
      return !!(await AuthService.getAccessToken());
    } catch (e) {
      console.log(e);
      return false;
    }
  },
);

export const initTokens = createAsyncThunk<boolean, string>(
  "auth/initTokens",
  async (code) => {
    try {
      await AuthService.initTokens(code);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  },
);

export const getAndSaveUserProfile = createAsyncThunk<User | null>(
  "auth/getAndSaveUserProfile",
  async () => {
    try {
      const user = await AuthService.getAndSaveUserProfile();
      await InternalStorageListsService.transferAnonymousDataToLoggedInUserAccount();
      SyncListsService.enqueue();
      return user;
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const logoutCleanup = createAsyncThunk<void>("auth/logoutCleanup", async () => {
  try {
    await AuthService.logoutCleanup();
  } catch (e) {
    console.log(e);
  }
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    toggleAuthError(state, action: { payload: boolean }) {
      state.authError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkSignIn.fulfilled, (state, action) => {
      state.signedIn = action.payload;
    });
    builder.addCase(initTokens.fulfilled, (state, action) => {
      state.authError = !action.payload;
    });
    builder.addCase(getAndSaveUserProfile.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.authError = false;
        state.signedIn = true;
        state.user = action.payload;
      }
    });
  },
});

export const { toggleAuthError } = authSlice.actions;
