import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import {
  Stack,
  Drawer,
  Divider,
  List,
  ListItem,
  Box,
  Button,
  Typography,
} from "@mui/material";

import "./Menu.scss";
import familyImage from "../../assets/family.png";
import { User } from "../../services/openapi";
import { UserLogoName } from "../UserLogoName/UserLogoName";

export interface MenuProps {
  open: boolean;
  onClose: () => void;
  user: User | null;
  onSignInClick: () => void;
  onSignOutClick: () => void;
  onShareList: () => void;
}

export const Menu = (props: MenuProps) => {
  return (
    <Drawer
      className="menu-drawer"
      anchor="left"
      open={props.open}
      onClose={props.onClose}
    >
      <Button className="menu-drawer-logo-button" variant="contained" color="primary">
        <Typography>Family List</Typography>
      </Button>
      <Box
        className="menu-drawer-logo-image"
        component="img"
        alt="Family list image"
        src={familyImage}
      />
      <Stack className="menu-drawer-login">
        {props.user !== null && props.user.name ? (
          <UserLogoName
            userLogoUrl={props.user.profileUrl || ""}
            userName={props.user.name}
          />
        ) : (
          <Button
            className="menu-drawer-list-button"
            onClick={props.onSignInClick}
            variant="text"
            color="secondary"
            startIcon={<AccountCircleOutlinedIcon />}
          >
            <Typography>Войти</Typography>
          </Button>
        )}
      </Stack>
      <Divider />
      <List className="menu-drawer-list">
        <ListItem>
          <Button
            className="menu-drawer-list-button"
            variant="text"
            color="primary"
            startIcon={<AddOutlinedIcon />}
            onClick={console.log}
          >
            <Typography>Создать новый список</Typography>
          </Button>
        </ListItem>
        <ListItem>
          <Button
            className="menu-drawer-list-button"
            variant="text"
            color="secondary"
            startIcon={<StarBorderOutlinedIcon />}
            onClick={console.log}
          >
            <Typography>PRO-версия</Typography>
          </Button>
        </ListItem>
        <ListItem>
          <Button
            className="menu-drawer-list-button"
            variant="text"
            color="secondary"
            startIcon={<EmailOutlinedIcon />}
            onClick={console.log}
          >
            <Typography>Обратная связь</Typography>
          </Button>
        </ListItem>
        <ListItem>
          <Button
            className="menu-drawer-list-button"
            variant="text"
            color="secondary"
            startIcon={<SettingsOutlinedIcon />}
            onClick={console.log}
          >
            <Typography>Настройки</Typography>
          </Button>
        </ListItem>
        <ListItem>
          <Button
            className="menu-drawer-list-button"
            variant="text"
            color="secondary"
            startIcon={<HelpOutlineOutlinedIcon />}
            onClick={console.log}
          >
            <Typography>Помощь</Typography>
          </Button>
        </ListItem>
        <ListItem>
          <Button
            className="menu-drawer-list-button"
            variant="text"
            color="secondary"
            startIcon={<ShareOutlinedIcon />}
            onClick={props.onShareList}
          >
            <Typography>Поделиться списком</Typography>
          </Button>
        </ListItem>
        {props.user && (
          <ListItem>
            <Button
              className="menu-drawer-list-button"
              variant="text"
              color="secondary"
              startIcon={<LogoutOutlinedIcon />}
              onClick={props.onSignOutClick}
            >
              <Typography>Выйти</Typography>
            </Button>
          </ListItem>
        )}
      </List>
    </Drawer>
  );
};
