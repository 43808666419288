import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

export interface DeleteListDialogProps {
  open: boolean;
  listName: string;
  onClose: () => void;
  onOk: () => void;
}

export const DeleteListDialog = (props: DeleteListDialogProps) => {
  const handleClose = () => {
    props.onClose();
  };
  const handleOk = () => {
    props.onOk();
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>Удалить доску</DialogTitle>
      <DialogContent>
        <Box>Вы уверены, что хотите удалить &ldquo;{props.listName}&rdquo;?</Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Нет</Button>
        <Button onClick={handleOk}>Да</Button>
      </DialogActions>
    </Dialog>
  );
};
