import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ListInternalModel } from "../../services/internalStorage/models/ListInternalModel";
import { ListItemCategoryInternalModel } from "../../services/internalStorage/models/ListItemCategoryInternalModel";
import { ListItemInternalModel } from "../../services/internalStorage/models/ListItemInternalModel";
import { InternalStorageCategoriesService } from "../../services/internalStorage/services/InternalStorageCategoriesService";
import { InternalStorageListsService } from "../../services/internalStorage/services/InternalStorageListsService";
import { ListDetailedDto, ListsService } from "../../services/openapi";
import { SyncListsService } from "../../services/sync/services/SyncListsService";
import { RootState } from "../store";

export type ListsStateType = {
  lists: ListInternalModel[];
  listItems: ListItemInternalModel[];
  selectedList: ListInternalModel | null;
  shareListKey: string | null;
  listToAccept: ListDetailedDto | null;
};

const initialState: ListsStateType = {
  lists: [],
  listItems: [],
  selectedList: null,
  shareListKey: null,
  listToAccept: null,
};

export const fetchLists = createAsyncThunk<Array<ListInternalModel>>(
  "lists/fetchLists",
  async () => {
    try {
      return await InternalStorageListsService.getLists();
    } catch (e) {
      console.log(e);
      return [];
    }
  },
);

export const fetchListItems = createAsyncThunk<Array<ListItemInternalModel>, number>(
  "lists/fetchListItems",
  async (listId) => {
    try {
      return await InternalStorageListsService.getListItems(listId);
    } catch (e) {
      console.log(e);
      return [];
    }
  },
);

export const createList = createAsyncThunk<ListInternalModel | null, string>(
  "lists/createList",
  async (name) => {
    try {
      return await InternalStorageListsService.createList({ name });
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const removeList = createAsyncThunk<number | null, number>(
  "lists/removeList",
  async (listId) => {
    try {
      return await InternalStorageListsService.deleteList(listId, true);
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const createListItem = createAsyncThunk<
  ListItemInternalModel | null,
  { localListId: number; name: string }
>("lists/createListItem", async ({ localListId, name }) => {
  try {
    const color = await InternalStorageCategoriesService.getCategoryByListItemName(name);
    return await InternalStorageListsService.createListItem({ localListId, name, color });
  } catch (e) {
    console.log(e);
    return null;
  }
});

export const toggleListItem = createAsyncThunk<
  ListItemInternalModel | null,
  { localId: number; isCompleted: boolean }
>("lists/toggleListItem", async ({ localId, isCompleted }) => {
  try {
    return await InternalStorageListsService.toggleListItem(localId, isCompleted);
  } catch (e) {
    console.log(e);
    return null;
  }
});

export const updateListItem = createAsyncThunk<void, { listItem: ListItemInternalModel }>(
  "lists/updateListItem",
  async ({ listItem }, { dispatch }) => {
    try {
      await InternalStorageListsService.updateListItem(listItem);
      dispatch(fetchListItems(listItem.localListId));
    } catch (e) {
      console.log(e);
    }
  },
);

export const deleteListItems = createAsyncThunk<
  number[] | null,
  { listItemsToDelete: Array<ListItemInternalModel> }
>("lists/deleteListItems", async ({ listItemsToDelete }) => {
  try {
    const deleteRequests = listItemsToDelete.map((listItem) =>
      InternalStorageListsService.deleteListItem(listItem.localId, true),
    );
    return await Promise.all(deleteRequests);
  } catch (e) {
    console.log(e);
    return null;
  }
});

export const getShareListKey = createAsyncThunk<string | null, number>(
  "lists/getShareListKey",
  async (listId) => {
    try {
      return await ListsService.getApiListsShareKey(listId);
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const getListByShareKey = createAsyncThunk<ListDetailedDto | null, string>(
  "lists/getListByShareKey",
  async (shareKey) => {
    try {
      return await ListsService.getApiListsShareKey1(shareKey);
    } catch (e) {
      console.log(e);
      return null;
    }
  },
);

export const acceptSharedList = createAsyncThunk<void, string>(
  "lists/getListByShareKey",
  async (shareKey) => {
    await ListsService.postApiListsShared({ sharedListKey: shareKey });
    SyncListsService.enqueue();
  },
);

export const saveListCategory = createAsyncThunk<void, ListItemCategoryInternalModel>(
  "lists/saveCategory",
  async ({ categoryColor, listItemName }) => {
    await InternalStorageCategoriesService.addListsCategory({
      categoryColor,
      listItemName,
    });
  },
);

export const syncLists = createAsyncThunk<void, void>("lists/runSyncLists", async () => {
  SyncListsService.enqueue();
});

export const runSyncLists = createAsyncThunk<void, number>(
  "lists/runSyncLists",
  async (interval, { dispatch, getState }) => {
    SyncListsService.run(() => {
      dispatch(fetchLists());
      const state = getState() as RootState;
      if (state.lists.selectedList !== null) {
        dispatch(fetchListItems(state.lists.selectedList.localId));
      }
    }, interval);
  },
);

export const listsSlice = createSlice({
  name: "lists",
  initialState,
  reducers: {
    selectList(state, action: { payload: { localId: number } }) {
      const newSelectedList = state.lists.find(
        (l) => l.localId === action.payload.localId,
      );
      if (newSelectedList) {
        state.selectedList = { ...newSelectedList };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLists.fulfilled, (state, action) => {
      const lists = action.payload
        .filter((l) => l.deleted === null)
        .sort((a, b) => a.order - b.order);
      state.lists = lists;
      if (lists.length > 0) {
        const updatedSelectedList = lists.find(
          (l) => l.localId === state.selectedList?.localId,
        );
        if (updatedSelectedList === undefined) {
          state.selectedList = { ...action.payload[0] };
        } else {
          state.selectedList = updatedSelectedList;
        }
      } else {
        state.selectedList = null;
        state.listItems = [];
      }
    });
    builder.addCase(fetchListItems.fulfilled, (state, action) => {
      state.listItems = action.payload.filter((li) => li.deleted === null);
    });
    builder.addCase(createList.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.lists.push(action.payload);
        state.selectedList = action.payload;
      }
    });
    builder.addCase(removeList.fulfilled, (state, action) => {
      state.lists = state.lists.filter((list) => list.localId !== action.payload);
      state.selectedList = state.lists.length ? state.lists[0] : null;
    });
    builder.addCase(createListItem.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.listItems.push(action.payload);
      }
    });
    builder.addCase(toggleListItem.fulfilled, (state, action) => {
      if (action.payload !== null) {
        const listIteLocalId = action.payload.localId;
        const listItemToChange = state.listItems.find(
          (listItem) => listItem.localId === listIteLocalId,
        );
        if (listItemToChange) {
          listItemToChange.isCompleted = action.payload.isCompleted;
        }
      }
    });
    builder.addCase(deleteListItems.fulfilled, (state, action) => {
      if (action.payload !== null) {
        const idsSet = new Set(action.payload);
        state.listItems = state.listItems.filter(
          (listItem) => !idsSet.has(listItem.localId),
        );
      }
    });
    builder.addCase(getShareListKey.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.shareListKey = action.payload;
      }
    });
    builder.addCase(getListByShareKey.fulfilled, (state, action) => {
      if (action.payload !== null) {
        state.listToAccept = action.payload;
      }
    });
  },
});

export const { selectList } = listsSlice.actions;
